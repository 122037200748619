import React, { useState } from "react";
import css from "./Pictures.module.css";

const Picture = ({ index, onClick, picture }) => {
  const [loaded, setLoaded] = useState(false);
  const { alt, src } = picture;

  return (
    <div className={css.container}>
      {src && alt && (
        <img
          style={{
            objectPosition: picture.right ? "100%" : "50%",
          }}
          loading="lazy"
          key={index}
          className={`${css.picture} ${loaded ? "" : css.unloaded}`}
          src={src}
          alt={alt}
          index={index}
          onLoad={() => {
            setLoaded(true);
          }}
          onClick={onClick}
        />
      )}
      {/* {!loaded && <div className={css.loading} />} */}
    </div>
  );
};

export default Picture;
