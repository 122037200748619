import React from "react";
import css from "./Card.module.css";

const Card = ({ button, children, img, placeholder, primary, secondary }) => {
  return (
    <div className={css.main}>
      {(img || placeholder) && (
        <div className={css.container}>
          {img?.src && img?.alt && (
            <img src={img.src} alt={img.alt} className={css.img} />
          )}
          {placeholder && <div className={css.placeholder}>PLACEHOLDER</div>}
        </div>
      )}
      <strong className={css.primary}>{primary}</strong>
      {secondary && <p className="o64 ctext">{secondary}</p>}
      {children}
      {button}
    </div>
  );
};

export default Card;
