import React from "react";
import Intro from "../../components/intro/Intro";
import Accordion from "../../components/accordion/Accordion";
import { Link } from "react-router-dom";
import Card from "../../components/card/Card";

const VideoGames = () => {
  return (
    <div className="main">
      <Intro
        primary="VIDEO GAMES"
        secondary="i really enjoy video games—playing them, watching them, making them. the concept of combining so many kinds of art then letting somebody else bring it all to life however they want to is so cool! if you want to check out what i'm up to in the games i'm playing..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>GAMES I'M PLAYING</h2>
        <div className="flex wrap">
          <Card primary="ROCKET LEAGUE" />
          <Card primary="HOLLOW KNIGHT" />
          <Card primary="RED DEAD REDEMPTION II" />
          <Card primary="ANIMAL CROSSING" />
          <Card primary="SUPER SMASH BROS. ULTIMATE" />
          <Card primary="MARIO KART 8 DELUXE" />
          <Card primary="ZELDA: TEARS OF THE KINGDOM" />
          <Card primary="ZELDA: BREATH OF THE WILD" />
          <Card primary="FORTNITE" />
        </div>

        <h2 style={{ marginTop: "4rem" }}>PLAY WITH ME</h2>
        <div className="flex wrap">
          <Card primary="NINTENDO SWITCH" secondary="SW-5569-6091-6023" />
          <Card primary="DISCORD" secondary="_squidslippers_" />
          <Card primary="GAMEPIGEON" secondary="801-867-8774" />
          <Card primary="XBOX" secondary="squidslippers" />
          <Card primary="EPIC GAMES" />
          <Card primary="STEAM" />
        </div>

        <h2 style={{ marginTop: "4rem" }}>PWNY EXPRESS</h2>
        <p className="o64 ctext">
          the pwny express is a software application i built for myself. it
          continuously scans{" "}
          <a
            href="https://xbl.io/"
            target="_blank"
            rel="noreferrer noopener"
            className="inlink"
          >
            the unofficial xbox api
          </a>{" "}
          and sends me a message on discord whenever my friends get on or off
          xbox live. (i configure a list of games and friends for it to watch
          for, and it only watches those.)
        </p>
        <p className="o64 ctext">
          i'm working on building out a user interface so other people can sign
          up and use this tool, too!
        </p>
      </div>
    </div>
  );
};

export default VideoGames;
