import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
import Accordion from "../../components/accordion/Accordion";
import Card from "../../components/card/Card";

const WoodBurning = () => {
  const woodburnings = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/potter.webp",
      alt: "harry potter and voldemort",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
      alt: "a husband and wife looking at each other",
      description:
        "a friend of mine commissioned this wood burning as a christmas gift.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/halo.webp",
      alt: "master chief",
      description: "this commission was one of my earliest wood burnings.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/jesus.webp",
      alt: "jesus",
      description: "i made this as a gift for a family member.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/elsa.webp",
      alt: "elsa",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/art/cover.webp",
      alt: "wedding guest book cover",
      description: "a wedding guest book cover a friend commissioned from me.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/gaten.webp",
      alt: "gaten matarazzo",
      description:
        "shoutout to when 'stranger things' was big and i made this.",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/wood_burning/capone.webp",
      alt: "capone",
      description: "a wood burning i made to honor my friend's late dog.",
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="WOOD BURNING"
        secondary="i'm a professional pyrographer, which is just a fancy way of saying i'd love to burn your portrait into wood. i specialize in single and couple portraits (people and pets)."
      />
      <div className="column p01">
        <h2>FOR EXAMPLE</h2>
        <Pictures pictures={woodburnings} />
        <div className="flex wrap">
          <Card primary="WEDDING" secondary="" />
          <Card primary="FUNERAL" secondary="" />
          <Card primary="ANNIVERSARY" secondary="" />
          <Card primary="BIRTHDAY" secondary="" />
          <Card primary="NEW BABY" secondary="" />
          <Card primary="VALENTINE'S" secondary="" />
          <Card primary="WINTER HOLIDAYS" secondary="" />
          <Card primary="GRADUATION" secondary="" />
          <Card primary="RELIGIOUS CELEBRATIONS" secondary="" />
        </div>

        <h2 style={{ marginTop: "4rem" }}>FOR SALE</h2>
        <p className="o64 ctext">sold out! check back soon.</p>
        <h2 style={{ marginTop: "4rem" }}>CUSTOM ORDERS</h2>
        <Accordion title="GET IN TOUCH">
          <p className="o64">
            send me the picture(s) you'd like me to use as reference. make sure
            the picture is clear, with nothing blocking the subject. (if the
            pictures don't work, i'll let you know.)
          </p>
          <p className="o64">
            then we'll hop on a call to discuss the project—and the subject.
            this step is especially meaningful for portrait pieces like
            memorials. this helps me capture their essence!
          </p>
          <a href="tel:+18018678774" className="link">
            801-867-8774
          </a>
          <a href="mailto:jacob.w.moreno@gmail.com" className="link">
            jacob.w.moreno@gmail.com
          </a>
        </Accordion>
        <Accordion title="MAKE SOME ART">
          <p className="o64">
            once we're all set, i'll get started on your custom order!
          </p>
          <p className="o64">
            depending on your preferences, i can film the process—or not—it's
            completely up to you. (even if i film it, you get to decide whom to
            share it with.)
          </p>
        </Accordion>
        <Accordion title="SPECIAL DELIVERY">
          <p className="o64">
            when i'm finished, i'll find a way to deliver it to you, safe and
            sound—in person or via mail. and i'll send you instructions for how
            to display and care for it.
          </p>
        </Accordion>
        <Accordion title="PRICING">
          <p className="o64">
            single portraits start at $600, with an average price of around
            $1,200.
          </p>
          <p className="o64">
            couples portraits start at $1,200 with an average price of around
            $1,800.
          </p>
        </Accordion>
        <div className="flex wrap">
          <Card
            primary="CALL ME"
            button={
              <a href="tel:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="TEXT ME"
            button={
              <a href="sms:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="EMAIL ME"
            button={
              <a href="mailto:jacob.w.moreno@gmail.com" className="link">
                jacob.w.moreno@gmail.com
              </a>
            }
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE ART"
            secondary="handmade wire names and charms!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-art">
                GET WIRE ART
              </Link>
            }
          />
          <Card
            primary="COLORED PAINCILS"
            secondary="fun for (almost) everybody!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                CHECK IT OUT
              </Link>
            }
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>MORE ART</h2>
        <Pictures
          key={1}
          pictures={[
            {
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
              description:
                "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/egg.webp",
              alt: "egg",
              description: "a hyper-realistic egg i drew using procreate.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/logo/ss.jpg",
              alt: "squidslippers",
              description: "my squidslippers logo! i love this little guy!",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
              alt: "pig",
              description: "an acrylic painting i made as a commission.",
            },
          ]}
        />
        <Link className="link" to={"/art"}>
          CHECK IT OUT
        </Link>
      </div>
    </div>
  );
};

export default WoodBurning;
