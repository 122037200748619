import React, { useContext } from "react";
import CartContext from "../../context/cart";
import css from "./Item.module.css";

const Item = ({
  description,
  email,
  id,
  specifics,
  sweepstakes,
  title,
  total,
}) => {
  const { prices, removeFromCart } = useContext(CartContext);

  return (
    <div className={css.main}>
      {title && <h3 className={css.title}>{title.toUpperCase()}</h3>}
      {description && <i className={css.description}>{description}</i>}
      <ul className={css.info}>
        <li className={css.lineItem}>
          {title === "custom wire art" && [
            <span key={0}>
              {specifics.type}: {specifics.customization || "—"}
            </span>,
            specifics.customization && specifics.type === "charm" && (
              <span key={1}>${prices.customization[specifics.customization]}</span>
            ),
            specifics.customization && specifics.type === "name/word" && (
              <span key={2}>${specifics.customization.length} </span>
            ),
          ]}
        </li>
        <li className={css.lineItem}>
          <span>style: {specifics.style || "—"}</span>
          {!!prices.style[specifics.style] && (
            <span>${prices.style[specifics.style]}</span>
          )}
        </li>
        <li className={css.lineItem}>
          <span>color: {specifics.color || "—"}</span>
        </li>
        <li className={`${css.lineItem} ${css.border}`}>
          <strong>total:</strong>
          {!!total && <strong>${total}</strong>}
        </li>
      </ul>
      {["yes", true].includes(sweepstakes) && (
        <p className={css.title}>sweepstakes entry: {email}</p>
      )}
      {id && (
        <button onClick={() => removeFromCart(id)} className={css.remove}>
          remove
        </button>
      )}
    </div>
  );
};

export default Item;
