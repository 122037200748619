import React, { useContext, useEffect, useRef, useState } from "react";

import CartContext from "../../context/cart";

import { Link } from "react-router-dom";
import { useHttp } from "../../helpers/useHttp";

import Input from "../../components/input/Input";
import Pictures from "../../components/pictures/Pictures";
import Radio from "../../components/radio/Radio";
import Item from "../../components/cart/Item";
import Intro from "../../components/intro/Intro";
import Card from "../../components/card/Card";

const WireArt = () => {
  const { cart, addToCart, calculateTotal, prices } = useContext(CartContext);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const typeRef = useRef(null);
  const wordRef = useRef(null);

  const [customization, setCustomization] = useState("");
  const [customizationError, setCustomizationError] = useState("");

  const [color, setColor] = useState("");
  const [colorError, setColorError] = useState("");

  const [style, setStyle] = useState("");
  const [styleError, setStyleError] = useState("");

  const [type, setType] = useState("charm");
  const [typeError, setTypeError] = useState("");

  const [total, setTotal] = useState(0);

  const { sendRequest } = useHttp();

  useEffect(() => {
    let total = calculateTotal({
      customization,
      style,
      title: "custom wire art",
      type,
    });
    setTotal(total);
  }, [calculateTotal, customization, style, type]);

  const addName = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      validate();

      let savedEntry = await sendRequest(
        "wire-name",
        "POST",
        JSON.stringify({
          specifics: {
            color,
            customization,
            style,
            type,
          },
        })
      );

      addToCart(savedEntry);

      setMessage(`✅ your submission was added to your cart.`);

      setCustomization("");
      setStyle("");
      setColor("");

      if (wordRef.current) {
        wordRef.current.focus();
      } else {
        typeRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      setMessage(error.message);
    }
    setLoading(false);
  };

  function validate() {
    let error = false;

    if (!["name/word", "charm"].includes(type)) {
      setTypeError("choose a valid type (name/word or charm)!");
      error = true;
    }

    switch (type) {
      case "name/word":
        if (!/^[A-Za-z .]+$/.test(customization)) {
          setCustomizationError(
            "enter a valid word or name (letters, spaces, and periods only)!"
          );
          if (typeRef.current) {
            typeRef.current.scrollIntoView({ behavior: "smooth" });
          }
          error = true;
        }
        break;
      case "charm":
        if (!["cactus", "heart", "lightning", "star"].includes(customization)) {
          setCustomizationError(
            "choose a valid charm (cactus, heart, lightning, or star)!"
          );
          error = true;
        }
        break;
      default:
        break;
    }

    if (!["copper", "gold", "rose gold", "silver"].includes(color)) {
      setColorError(
        "choose a valid color (copper, gold, rose gold, or silver)!"
      );
      error = true;
    }

    if (!["earring", "keychain", "necklace", "standalone"].includes(style)) {
      setStyleError(
        "choose a valid style (earring, keychain, necklace, or standalone)!"
      );
      error = true;
    }

    if (error) {
      throw {
        code: 400,
        name: "WIRE NAME: INVALID INPUT",
      };
    }
  }

  const changeColor = (color) => {
    setMessage("");
    setColorError("");
    setColor(color);
  };

  const changeCustomization = (e) => {
    setMessage("");
    setCustomizationError("");
    setCustomization(e.target.value);
  };

  const changeCustomization2 = (shape) => {
    setMessage("");
    setCustomizationError("");
    setCustomization(shape);
  };

  const changeStyle = (style) => {
    setMessage("");
    setStyleError("");
    setStyle(style);
  };

  const changeType = (type) => {
    setMessage("");
    setTypeError("");
    setCustomization("");
    setCustomizationError("");
    setType(type);
  };

  let disabled =
    customization?.length === 0 || style?.length === 0 || color?.length === 0;

  return (
    <div className="main">
      <Intro
        primary="WIRE ART"
        secondary="if you're looking for [ high-quality / custom / hand-made ] names, charms, and other wire art..."
        tertiary="you're in the right place."
      />

      <div className="column p01">
        <h2>FOR EXAMPLE</h2>
        <Pictures
          pictures={[
            {
              src: require("./lucas.jpg"),
              alt: "The name Lucas made out of wire.",
            },
            {
              src: require("./jess.jpg"),
              alt: "The name Jess made out of wire.",
            },
            {
              src: require("./isaac.jpg"),
              alt: "The name Isaac made out of wire.",
            },
            {
              src: require("./ed.jpg"),
              alt: "The name Ed made out of wire.",
            },
            {
              src: require("./billy.jpg"),
              alt: "The name Billy made out of wire.",
            },
            {
              src: require("./bubbles.jpg"),
              alt: "The name Bubbles made out of wire.",
            },
            {
              src: require("./joy.jpg"),
              alt: "The name Joy made out of wire.",
            },
            {
              src: require("./ernesto.jpg"),
              alt: "The name Ernesto made out of wire.",
            },
            {
              src: require("./jacob.jpg"),
              alt: "The name Jacob made out of wire.",
            },
            {
              src: require("./kaz.jpg"),
              alt: "The name Kaz made out of wire.",
            },
            {
              src: require("./jason.jpg"),
              alt: "The name Jason made out of wire.",
            },
          ]}
        />
        <div className="flex wrap">
          <Card primary="STOCKING NAMETAG" secondary="" />
          <Card primary="TREE ORNAMENT" secondary="" />
          <Card primary="GIFT TAG" secondary="" />
          <Card primary="MEAL PLACE CARD" secondary="" />
          <Card primary="CAKE TOPPER" secondary="" />
          <Card primary="PARTY FAVOR" secondary="" />
          <Card primary="KEYCHAIN" secondary="" />
          <Card primary="PLANT LABEL" secondary="" />
          <Card primary="NECKLACE" secondary="" />
        </div>
        <h2 style={{ marginTop: "4rem" }}>GET YOUR OWN</h2>
        <form onSubmit={addName}>
          <Radio
            error={typeError}
            onChange={changeType}
            options={[
              { display: "charm", value: "charm" },
              { display: "name/word", value: "name/word" },
            ]}
            selected={type}
            title="choose a type"
            ref={typeRef}
          />
          {type === "name/word" && (
            <Input
              autoFocus={true}
              error={customizationError}
              label="enter a name/word"
              onChange={changeCustomization}
              ref={wordRef}
              value={customization}
            >
              ${parseInt(customization.length)}
            </Input>
          )}
          {type === "charm" && (
            <Radio
              error={customizationError}
              onChange={changeCustomization2}
              options={[
                {
                  display: "cactus",
                  value: "cactus",
                  right: prices.customization.cactus,
                },
                {
                  display: "heart",
                  value: "heart",
                  right: prices.customization.heart,
                },
                {
                  display: "lightning",
                  value: "lightning",
                  right: prices.customization.lightning,
                },
                {
                  display: "star",
                  value: "star",
                  right: prices.customization.star,
                },
              ]}
              selected={customization}
              title="choose a shape"
            />
          )}
          <Radio
            error={styleError}
            onChange={changeStyle}
            options={[
              {
                display: "earring",
                value: "earring",
                right: prices.style.earring,
              },
              {
                display: "keychain",
                value: "keychain",
                right: prices.style.keychain,
              },
              {
                display: "necklace",
                value: "necklace",
                right: prices.style.necklace,
              },
              { display: "standalone", value: "standalone" },
            ]}
            selected={style}
            title="choose a style"
          />
          <Radio
            error={colorError}
            onChange={changeColor}
            options={[
              { display: "copper", value: "copper" },
              { display: "gold", value: "gold" },
              { display: "rose gold", value: "rose gold" },
              { display: "silver", value: "silver" },
            ]}
            selected={color}
            title="choose a color"
          />

          <Item
            description={`a personalized wire charm or name/word that comes in your choice of color and style.`}
            specifics={{ customization, style, color, type }}
            title="YOUR ITEM"
            total={total}
          />

          <button
            className={disabled ? "disabled" : null}
            type="submit"
            style={{ marginTop: 0 }}
            disabled={disabled}
          >
            {loading ? "loading..." : "ADD TO CART"}
          </button>
          {cart.length > 0 && (
            <Link
              style={{ marginTop: "-1rem" }}
              className="link"
              to="/shop/cart"
            >
              CONTINUE TO CART
            </Link>
          )}
        </form>

        <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="COLORED PAINCILS"
            secondary="fun for (almost) everybody!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                CHECK IT OUT
              </Link>
            }
          />
          <Card
            primary="WOOD BURNING"
            secondary="professional pyrogrpahy portraits!"
            button={
              <Link className="link" to="/wood-burning">
                GET A WOOD BURNING
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>MORE ART</h2>
        <Pictures
          key={1}
          pictures={[
            {
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
              description:
                "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/egg.webp",
              alt: "egg",
              description: "a hyper-realistic egg i drew using procreate.",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/logo/ss.jpg",
              alt: "squidslippers",
              description: "my squidslippers logo! i love this little guy!",
            },
            {
              src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
              alt: "pig",
              description: "an acrylic painting i made as a commission.",
            },
          ]}
        />

        <Link className="link" to={"/art"}>
          CHECK IT OUT
        </Link>
      </div>
    </div>
  );
};

export default WireArt;
