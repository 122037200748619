import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Card from "../../components/card/Card";
import Pancakes from "./previews/Pancakes";
import BrusselsSproutsAndBacon from "./previews/BrusselsSproutsAndBacon";
import EggDropSoup from "./previews/EggDropSoup";

const VirtualCookbook = () => {
  return (
    <div className="main">
      <Intro
        primary="VIRTUAL COOKBOOK"
        secondary="i actually started practicing cooking for the sake of this project: a virtual cookbook that's actually helpful, with an interactive checklist of easy-to-follow instructions, built-in timers, and more resources to help make cooking easier and more fun. i now enjoy cooking quite a bit and have since practiced at least enough to have a few recipes that i love to make (and eat). if you want to cook some of the dishes i've made..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>DISHES I LOVE</h2>
        <div className="flex wrap">
          <BrusselsSproutsAndBacon />
          <Pancakes />
          <Card
            primary="GARLIC ONION STEAK"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/food/steak.webp",
              alt: "steak",
            }}
            button={<p className="flink">COMING SOON</p>}
          />
          <EggDropSoup />
        </div>
        <h2 style={{ marginTop: "4rem" }}>DISHES I HATE</h2>
        <Card
          primary="INCINERATED TOAST"
          img={{
            src: require("./toast.png"),
            alt: "a charred piece of toast",
          }}
          button={
            <Link className="link" to="/food/incinerated-toast">
              TRY IT
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default VirtualCookbook;
