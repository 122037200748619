import React from "react";
import css from "./VideoGames.module.css";
import Intro from "../../components/intro/Intro";
import Accordion from "../../components/accordion/Accordion";
import Pictures from "../../components/pictures/Pictures";
import alfonso1 from "./pictures/neighbors/alfonso1.JPG";
import alfonso2 from "./pictures/neighbors/alfonso2.JPG";
import alfonso3 from "./pictures/neighbors/alfonso3.JPG";
import alfonso4 from "./pictures/neighbors/alfonso4.JPG";
import coach1 from "./pictures/neighbors/coach1.JPG";
import coach2 from "./pictures/neighbors/coach2.JPG";
import coach3 from "./pictures/neighbors/coach3.JPG";
import coach4 from "./pictures/neighbors/coach4.JPG";
import gladys1 from "./pictures/neighbors/gladys1.JPG";
import gladys2 from "./pictures/neighbors/gladys2.JPG";
import gladys3 from "./pictures/neighbors/gladys3.JPG";
import gladys4 from "./pictures/neighbors/gladys4.JPG";
import merengue1 from "./pictures/neighbors/merengue1.JPG";
import merengue2 from "./pictures/neighbors/merengue2.JPG";
import merengue3 from "./pictures/neighbors/merengue3.JPG";
import merengue4 from "./pictures/neighbors/merengue4.JPG";
import pate1 from "./pictures/neighbors/pate1.JPG";
import pate2 from "./pictures/neighbors/pate2.JPG";
import pate3 from "./pictures/neighbors/pate3.JPG";
import pate4 from "./pictures/neighbors/pate4.JPG";
import piper1 from "./pictures/neighbors/piper1.JPG";
import piper2 from "./pictures/neighbors/piper2.JPG";
import piper3 from "./pictures/neighbors/piper3.JPG";
import piper4 from "./pictures/neighbors/piper4.JPG";
import sidney1 from "./pictures/neighbors/sidney1.JPG";
import sidney2 from "./pictures/neighbors/sidney2.JPG";
import sidney3 from "./pictures/neighbors/sidney3.JPG";
import business from "./pictures/me/business.JPG";
import cutie from "./pictures/me/cutie.JPG";
import peach from "./pictures/me/peach.JPG";
import queen from "./pictures/me/queen.JPG";
import me1 from "./pictures/intro/me1.png";
import me2 from "./pictures/intro/me2.png";
import me3 from "./pictures/intro/me3.png";
import eyebrows from "./pictures/designs/eyebrows.png";
import fossil from "./pictures/designs/fossil.png";
import squidslippers from "./pictures/designs/squidslippers.png";
import sweater from "./pictures/designs/sweater.JPG";

const AnimalCrossing = () => {
  return (
    <div className="main">
      <Intro
        primary="ANIMAL CROSSING"
        secondary="i love this game!! it's so fun and awesome!!"
        pictures={[
          { src: me1, alt: "squilbert" },
          { src: me2, alt: "squilbert" },
          { src: me3, alt: "squilbert" },
        ]}
      />
      <div className="column p01">
        <h2>SQUILBERT</h2>
        <Pictures
          pictures={[
            {
              src: queen,
              alt: "queen squilbert",
              description: "squilbert!",
            },
            {
              src: cutie,
              alt: "squilbert pondering",
              description: "squilbert!",
            },
            {
              src: peach,
              alt: "squilbert waving",
              description: "squilbert!",
            },
            {
              src: business,
              alt: "squilbert smiling",
              description: "squilbert!",
            },
          ]}
        />
        <Accordion title={`"SQUILBERT"?`}>
          <p className="o64">
            i'll say this right now: i am <i>not</i> good at naming things in
            this game. at first, my character was a boy, and i decided to name
            him "squilbert". it only took me a few hours of playing to decide i
            actually wanted my character to be a girl, and around that same time
            is when i learned i can't change my name, sooo...
          </p>
          <p className="o64">yeah. squilbert the girl. comin' at ya live.</p>
          <p className="o64">oops!</p>
        </Accordion>
        <Accordion title="PLAY WITH ME">
          <p className="o64">
            <strong>friend code:</strong> SW-5569-6091-6023
          </p>
        </Accordion>
        <Accordion soon title="WAND OUTFITS"></Accordion>
        <Accordion soon title="MY HOUSE"></Accordion>

        <h2>NEIGHBORS</h2>
        <Pictures
          pictures={[
            {
              src: alfonso4,
              alt: "alfonso falling asleep",
              description: "alfonso!",
            },
            {
              src: sidney2,
              alt: "sidney pondering",
              description: "sidney!",
            },
            {
              src: pate1,
              alt: "pate being adorable",
              description: "pate!",
            },
            {
              src: gladys3,
              alt: "gladys stretching",
              description: "gladys!",
            },
            {
              src: merengue3,
              alt: "merengue saying hi",
              description: "merengue!",
            },
            {
              src: coach3,
              alt: "coach flexing",
              description: "coach!",
            },
            {
              src: piper1,
              alt: "piper slaying",
              description: "piper!",
            },
          ]}
        />
        <Accordion title="ALFONSO">
          <Pictures
            pictures={[
              {
                src: alfonso1,
                alt: "alfonso laughing",
                description: "alfonso!",
              },
              {
                src: alfonso2,
                alt: "alfonso sitting",
                description: "alfonso!",
              },
              {
                src: alfonso3,
                alt: "alfonso saying 'cheese'",
                description: "alfonso!",
              },
              {
                src: alfonso4,
                alt: "alfonso falling asleep",
                description: "alfonso!",
              },
            ]}
          />
          <p className="o64">
            alfonso has been with us since near the beginning, and we LOVE him.
            he is so fun and nice and cool!
          </p>
          <p className="o64">
            <strong>birthday:</strong> june 9th
          </p>
          <p className="o64">
            <strong>personality:</strong> lazy
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "it's-a me"
          </p>
          <p className="o64">
            <strong>hobby:</strong> play (he can run around with his arms
            extended whenever he wants!)
          </p>
        </Accordion>
        <Accordion title="SIDNEY">
          <Pictures
            pictures={[
              {
                src: sidney1,
                alt: "sidney saying 'cheese'",
                description: "sidney!",
              },
              {
                src: sidney2,
                alt: "sidney pondering",
                description: "sidney!",
              },
              {
                src: sidney3,
                alt: "sidney serving",
                description: "sidney!",
              },
              {
                src: sidney1,
                alt: "sidney saying 'cheese'",
                description: "sidney!",
              },
            ]}
          />
          <p className="o64">sidney is great. she's super nice and crafty.</p>
          <p className="o64">
            <strong>birthday:</strong> june 21st
          </p>
          <p className="o64">
            <strong>personality:</strong> normal
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "sunshine"
          </p>
          <p className="o64">
            <strong>hobby:</strong> music (she can sing whenever she wants!)
          </p>
        </Accordion>
        <Accordion title="COACH">
          <Pictures
            pictures={[
              {
                src: coach1,
                alt: "coach saying 'cheese'",
                description: "coach!",
              },
              {
                src: coach2,
                alt: "coach flexing",
                description: "coach!",
              },
              {
                src: coach3,
                alt: "coach flexing",
                description: "coach!",
              },
              {
                src: coach4,
                alt: "coach flexing",
                description: "coach!",
              },
            ]}
          />
          <p className="o64">
            coach is fine. anika loves him. i think he's fine. (he's fine.)
          </p>
          <p className="o64">
            <strong>birthday:</strong> april 29th
          </p>
          <p className="o64">
            <strong>personality:</strong> jock
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "stubble"
          </p>
          <p className="o64">
            <strong>hobby:</strong> fitness (he wears sunglasses and works out a
            lot!)
          </p>
        </Accordion>
        <Accordion title="GLADYS">
          <Pictures
            pictures={[
              {
                src: gladys1,
                alt: "gladys saying 'cheese'",
                description: "gladys!",
              },
              {
                src: gladys2,
                alt: "gladys flexing",
                description: "gladys!",
              },
              {
                src: gladys3,
                alt: "gladys flexing",
                description: "gladys!",
              },
              {
                src: gladys4,
                alt: "gladys flexing",
                description: "gladys!",
              },
            ]}
          />
          <p className="o64">
            gladys is definitely one of my very favorite villagers. she's cute
            and fun and stops by my house like once a day.
          </p>
          <p className="o64">
            <strong>birthday:</strong> january 15th
          </p>
          <p className="o64">
            <strong>personality:</strong> normal
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "stretch"
          </p>
          <p className="o64">
            <strong>hobby:</strong> education (she wears glasses, reads outside,
            and examines things with a magnifying glass!)
          </p>
        </Accordion>
        <Accordion title="MERENGUE">
          <Pictures
            pictures={[
              {
                src: merengue1,
                alt: "merengue sitting",
                description: "merengue!",
              },
              {
                src: merengue2,
                alt: "merengue waving",
                description: "merengue!",
              },
              {
                src: merengue3,
                alt: "merengue saying 'cheese'",
                description: "merengue!",
              },
              {
                src: merengue4,
                alt: "merengue facing the camera looking like a goof",
                description: "merengue!",
              },
            ]}
          />
          <p className="o64">
            merengue is cool. she's very friendly, like the waitress at a diner
            who calls you "sugar".
          </p>
          <p className="o64">
            <strong>birthday:</strong> march 19th
          </p>
          <p className="o64">
            <strong>personality:</strong> normal
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "shortcake"
          </p>
          <p className="o64">
            <strong>hobby:</strong> nature (she reads books about flowers, bugs,
            fish, and fossils!)
          </p>
        </Accordion>
        <Accordion title="PIPER">
          <Pictures
            pictures={[
              {
                src: piper1,
                alt: "piper serving",
                description: "piper!",
              },
              {
                src: piper2,
                alt: "piper looking at her phone",
                description: "piper!",
              },
              {
                src: piper3,
                alt: "piper saying 'cheese'",
                description: "piper!",
              },
              {
                src: piper4,
                alt: "piper waving",
                description: "piper!",
              },
            ]}
          />
          <p className="o64">piper is a worse version of pate.</p>
          <p className="o64">
            <strong>birthday:</strong> april 18th
          </p>
          <p className="o64">
            <strong>personality:</strong> peppy
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "chickadee"
          </p>
          <p className="o64">
            <strong>hobby:</strong> play (she can run around with her arms
            extended whenever she wants!)
          </p>
        </Accordion>
        <Accordion title="PATE">
          <Pictures
            pictures={[
              {
                src: pate1,
                alt: "pate being a model",
                description: "pate!",
              },
              {
                src: pate2,
                alt: "pate dancing",
                description: "pate!",
              },
              {
                src: pate3,
                alt: "pate taking a picture with her phone",
                description: "pate!",
              },
              {
                src: pate4,
                alt: "pate serving",
                description: "pate!",
              },
            ]}
          />
          <p className="o64">
            pate is awesome! she's super fun and funny and nice and cute.
          </p>
          <p className="o64">
            <strong>birthday:</strong> february 23rd
          </p>
          <p className="o64">
            <strong>personality:</strong> peppy
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "quackle"
          </p>
          <p className="o64">
            <strong>hobby:</strong> fashion (she wears special purses, headwear,
            and other accessory items!)
          </p>
        </Accordion>
        <Accordion title="AUDIE">
          <p className="o64">
            audie's fine. i keep forgetting she's a villager, and i often forget
            her name.
          </p>
          <p className="o64">
            <strong>birthday:</strong> august 31st
          </p>
          <p className="o64">
            <strong>personality:</strong> peppy
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "foxtrot"
          </p>
          <p className="o64">
            <strong>hobby:</strong> fitness (she wears sunglasses and works out
            a lot!)
          </p>
        </Accordion>
        <Accordion title="ELOISE">
          <p className="o64">i like eloise. she's pretty cool.</p>
          <p className="o64">
            <strong>birthday:</strong> december 8th
          </p>
          <p className="o64">
            <strong>personality:</strong> snooty
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "tooooot"
          </p>
          <p className="o64">
            <strong>hobby:</strong> fashion (she wears special purses, headwear,
            and other accessory items!)
          </p>
        </Accordion>
        <Accordion title="PLUCKY">
          <p className="o64">
            plucky is the WORST! she is UGLY and LAME and i have hit her in the
            head and face with my net THOUSANDS of times! i want her GONE!
          </p>
          <p className="o64">
            <strong>birthday:</strong> october 12th
          </p>
          <p className="o64">
            <strong>personality:</strong> big sister
          </p>
          <p className="o64">
            <strong>catchphrase:</strong> "chicky-poo"
          </p>
          <p className="o64">
            <strong>hobby:</strong> play (she can run around with her arms
            extended whenever she wants!)
          </p>
        </Accordion>

        {/* <Accordion title="OLD NEIGHBORS">
          <Pictures pictures={[{}, {}, {}]} />
        </Accordion> */}

        <h2>DESIGNS</h2>
        <p className="o64 ctext">
          i love making designs on here. these are only a few. as time goes,
          i'll add more designs, show them in action, and even include some
          tutorials or design codes so you can make your own or borrow mine.
        </p>
        <Pictures
          pictures={[
            { src: eyebrows, alt: "eyebrows" },
            { src: fossil, alt: "fossil" },
            { src: squidslippers, alt: "squidslippers" },
            { src: sweater, alt: "sweater" },
          ]}
        />
        <Accordion soon title="EYEBROWS & FRECKLES"></Accordion>
        <Accordion soon title="FAKE FOSSIL"></Accordion>
        <Accordion soon title="SQUIDSLIPPERS LOGO"></Accordion>
        <Accordion soon title="CUTE SWEATER"></Accordion>

        <h2>MUSEUM</h2>
        <Accordion soon title="FISH"></Accordion>
        <Accordion soon title="FOSSILS"></Accordion>
        <Accordion soon title="ART"></Accordion>
        <Accordion soon title="BUGS"></Accordion>
      </div>
    </div>
  );
};

export default AnimalCrossing;
