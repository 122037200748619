import React, { useEffect, useState } from "react";
import css from "./Intro.module.css";

const Intro = (props) => {
  const { button, children, pictures, primary, secondary, tertiary } = props;
  const [index, setIndex] = useState(0);

  const defaultPics = [
    {
      src: "https://storage.googleapis.com/squidslippers-media/me/self_portrait1.webp",
      alt: "me",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/me/self_portrait2.webp",
      alt: "me",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/me/self_portrait3.webp",
      alt: "me",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/me/self_portrait4.webp",
      alt: "me",
    },
    {
      src: "https://storage.googleapis.com/squidslippers-media/me/self_portrait5.webp",
      alt: "me",
    },
  ];

  let displayed = pictures || defaultPics;

  useEffect(() => {
    setTimeout(
      () =>
        setIndex((current) => {
          if (current === displayed?.length - 1) return 0;
          else return (current += 1);
        }),
      3000
    );
  }, [index, displayed?.length]);

  return (
    <div className={css.main}>
      <div className={css.left}>
        {primary && <h1 className={css.primary}>{primary}</h1>}
        {secondary && (
          <p style={{ padding: 0 }} className="o64">
            {secondary}
          </p>
        )}
        {tertiary && <p style={{ padding: 0 }}>{tertiary}</p>}
        {children && children}
        {button && button}
      </div>
      <div className={css.carousel}>
        {displayed.map((picture, i) => (
          <img
            src={picture.src}
            alt={picture.alt}
            className={i === index ? css.me_visible : css.me_invisible}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default Intro;
