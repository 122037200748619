import React from "react";
import Intro from "../../components/intro/Intro";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";
import LI from "../../components/LI/LI";
import Accordion from "../../components/accordion/Accordion";

const ContentAndDesign = () => {
  return (
    <div className="main">
      <Intro
        primary="CONTENT + DESIGN"
        secondary="i have 10+ years of professional experience as a content creator. i've dabbled in photography, videography, art, and writing, and i've participated in the creation and execution of several successful content marketing campaigns."
      />
      <div className="column p01">
        <h2>FOR EXAMPLE</h2>
        <div className="flex wrap">
          <Card
            primary="PHOTOGRAPHY"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/lifestyle/feet.webp",
              alt: "feet prints in sand",
            }}
            button={
              <Link className="link" to="/photography">
                CHECK IT OUT
              </Link>
            }
          />
          <Card
            primary="VIDEOGRAPHY"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/asmr2x1.png",
              alt: "me being hypnotized",
            }}
            button={
              <a
                href="https://www.youtube.com/@squidslippers/videos"
                target="_blank"
                rel="noreferrer noopener"
                className="link"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="DIGITAL ART"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/mxm2x1.jpg",
              alt: "mantle experience management logo",
            }}
            button={
              <Link className="link" to="/art">
                CHECK IT OUT
              </Link>
            }
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>ENGAGE YOUR AUDIENCE</h2>
        <Accordion title="CONTACT + COORDINATE">
          <LI>
            <p>
              reach out to me (in whichever way you prefer) and talk about your
              vision and the content you want to make.
            </p>
          </LI>
          <LI>
            <p>
              don't worry if you need help defining what you want—i can help
              with that too! i have plenty of experience with content marketing
              and strategy.
            </p>
          </LI>
          <LI>
            <p>
              once we're on the same page, we'll talk about timelines and costs,
              giving you a clear understanding of exactly what it will take to
              bring your vision to life.
            </p>
          </LI>
        </Accordion>
        <Accordion title="CONTENT + CREATION">
          <LI>
            <p>
              i make the content. photographs, videos, icons, logos, scripts,
              product descriptions, web pages—you name it, i've got you covered.
            </p>
          </LI>
          <LI>
            <p>
              i'll check in with you for feedback along the way to make sure
              we're bringing YOUR vision to life.
            </p>
          </LI>
        </Accordion>
        <Accordion title="ADVERTISING + MARKETING">
          <LI>
            <p>
              after that, it's time to spread the word! i'd love to help you
              integrate this engaging content into your website.
            </p>
          </LI>
          <LI>
            <p>
              and we can talk about my experience with social media management.
              i've gone viral on every major social media platform, and you can
              too.
            </p>
          </LI>
          <Link className="link" to="/software-and-websites">
            SOFTWARE + WEBSITES
          </Link>
        </Accordion>

        <div className="flex wrap">
          <Card
            primary="CALL ME"
            button={
              <a href="tel:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="TEXT ME"
            button={
              <a href="sms:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="EMAIL ME"
            button={
              <a href="mailto:jacob.w.moreno@gmail.com" className="link">
                jacob.w.moreno@gmail.com
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ContentAndDesign;
