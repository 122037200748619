import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../../context/cart";
import css from "./Order.module.css";
import { Link } from "react-router-dom";

const Order = () => {
  let lastOrder = JSON.parse(sessionStorage.getItem("last-order"));
  const [order] = useState(lastOrder);
  const { clearCart } = useContext(CartContext);

  useEffect(() => {
    clearCart();
  }, []);

  if (!order) {
    return (
      <div className={`${css.main} gradient`}>
        <h1>THANK YOU!</h1>
        <p className="o64 ctext">your order has been received.</p>
        <h2>WHAT'S NEXT</h2>
        <Link to="/" className="link">
          GO BACK HOME
        </Link>
        <Link to="/sweepstakes/live" className="link">
          KEEP SHOPPING
        </Link>
      </div>
    );
  }
  return (
    <div className={`${css.main} gradient`}>
      <h1>thank you!</h1>
      <ul className={css.list}>
        <h2>order details</h2>
        <li className={css.item}>
          <p>order id:</p>
          <span>{order.id}</span>
        </li>
        <li className={css.item}>
          <p>order date:</p>
          <span>{new Date(order.date).toDateString()}</span>
        </li>
        <li className={css.item}>
          <p>items:</p>
          <span>{order.items.length}</span>
        </li>
        <li className={css.item}>
          <strong>total:</strong>
          <strong>${order.payment.total}</strong>
        </li>
      </ul>

      <ul className={css.list}>
        <h2>cost breakdown</h2>
        {order.items.map((item, index) => (
          <li className={css.item} key={index}>
            <p className={css.title}>{item.title}</p>
            <span className={css.description} style={{ textAlign: "left" }}>
              {item.specifics.color} {item.specifics.customization}{" "}
              {item.specifics.style}
            </span>
            <span>${item.specifics.total}</span>
          </li>
        ))}
      </ul>

      <ul className={css.list}>
        <h2>customer information</h2>
        <li className={css.item}>
          <p>name:</p>
          <span>{order.customer.name}</span>
        </li>
        <li className={css.item}>
          <p>address:</p>
          <p className={css.address}>
            <span>{order.customer.address.line1}</span>
            {order.customer.address.line2 && (
              <span>${order.customer.address.line2}</span>
            )}
            <br />
            <span>
              {order.customer.address.city}, {order.customer.address.state}{" "}
              {order.customer.address.postal_code}
            </span>
          </p>
        </li>
        <li className={css.item}>
          <p>email:</p>
          <span>{order.customer.email}</span>
        </li>
        {order.customer.phone && (
          <li className={css.item}>
            <p>phone:</p>
            <p>{order.customer.phone}</p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Order;
