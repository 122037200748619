import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CartContext from "../../context/cart";
import css from "./Cart.module.css";
import Card from "../../components/card/Card";

const Cart = () => {
  const { cart, removeFromCart } = useContext(CartContext);
  let price = cart.reduce((acc, curr) => {
    return acc + curr.specifics.total;
  }, 0);

  if (cart.length > 0) {
    return (
      <div className="main column">
        <h1 style={{ marginTop: "4rem" }}>YOUR CART</h1>
        <ul className={css.list} style={{ alignItems: "center" }}>
          {cart.map((entry, index) => {
            switch (entry.title) {
              case "colored paincil":
                return (
                  <Card
                    key={index}
                    primary={entry.title.toUpperCase()}
                    secondary={`${entry.specifics.color} — $${entry.specifics.total}`}
                    img={{
                      src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
                      alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
                    }}
                    button={
                      <button
                        onClick={() => removeFromCart(entry.id)}
                        className={css.remove}
                      >
                        REMOVE
                      </button>
                    }
                  />
                );

              case "custom wire art":
                return (
                  <Card
                    key={index}
                    primary={entry.title.toUpperCase()}
                    secondary={`${entry.specifics.color} ${entry.specifics.customization} ${entry.specifics.style} — $${entry.specifics.total}`}
                    img={{
                      src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
                      alt: "wire names scattered across a black background",
                    }}
                    button={
                      <button
                        onClick={() => removeFromCart(entry.id)}
                        className={css.remove}
                      >
                        REMOVE
                      </button>
                    }
                  />
                );

              default:
                return;
            }
          })}
        </ul>
        <h1 className={css.total}>
          <strong>TOTAL:</strong>
          <strong>${price}</strong>
        </h1>
        <Link to="/shop/checkout" className="button">
          CONTINUE TO CHECKOUT
        </Link>
        <h2 style={{ marginTop: "4rem" }}>KEEP SHOPPING</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE ART"
            secondary="handmade wire names and charms!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-art">
                GET SOME
              </Link>
            }
          />

          <Card
            primary="COLORED PAINCILS"
            secondary="fun for (almost) everybody!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                GET ONE
              </Link>
            }
          />

          <Card
            primary="WOOD BURNING"
            secondary="professional pyrogrpahy portraits!"
            button={
              <Link className="link" to="/wood-burning">
                GET ONE
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={css.main}>
        <h1>*GASP* IT'S EMPTY</h1>
        <p className="ctext">your cart is empty. let's fill it!</p>
        <div className="flex wrap">
          <Card
            primary="WIRE ART"
            secondary="handmade wire names and charms!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-art">
                GET SOME
              </Link>
            }
          />

          <Card
            primary="COLORED PAINCILS"
            secondary="fun for (almost) everybody!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                GET ONE
              </Link>
            }
          />

          <Card
            primary="WOOD BURNING"
            secondary="professional pyrogrpahy portraits!"
            button={
              <Link className="link" to="/wood-burning">
                GET ONE
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>
      </div>
    );
  }
};

export default Cart;
