import React, { useState } from "react";
import css from "./ColoredPaincils.module.css";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";
import Form from "./Form";

import Accordion from "../../components/accordion/Accordion";

const ColoredPaincils = () => {
  const [form, setForm] = useState(false);

  const toggleForm = () => {
    setForm((old) => !old);
  };

  return (
    <div className="main">
      {form && <Form close={() => setForm(false)} />}
      <div className={css.intro}>
        <h1>COLORED PAINCILS</h1>
        <i>FUN FOR EVERYONE !</i>
        <button
          className="button"
          style={{
            marginTop: "1rem",
            marginBottom: "3rem",
            maxWidth: "36rem",
          }}
          onClick={toggleForm}
        >
          GET ONE
        </button>
        <img
          className={css.img}
          src={
            "https://storage.googleapis.com/squidslippers-media/colored_paincils/background.png"
          }
        />
      </div>
      <div className={css.background}>
        <div className="column p01">
          <h2>WHAT'S A COLORED PAINCIL?</h2>
          <p className="o64 ctext">
            the colored paincil is a branch of strong wood (oak) that i carve
            and paint to look like a larger-than-life colored pencil. it looks
            fun and playful, but this self-defense device packs quite a punch!
            check out some of the things that make it special:
          </p>
          <div className="flex wrap">
            <Card primary="SAFER PUNCHES" />
            <Card primary="24/7 PROTECTION" />
            <Card primary="MUCH STRONGER STRIKES" />
            <Card primary="CUTER ACCESSORIES" />
            <Card primary="LIFETIME WARRANTY" />
            <Card primary="PERFECT HOLIDAY GIFT" />
          </div>
          {/* <Pictures
            pictures={[
              {
                src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/line.webp",
                alt: "colored pencils falling down a white background.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/descending.webp",
                alt: "giant colored pencils in a line. they're side-by-side, directly adjacent, with the smallest on the left and the largest on the right. from left to right, they're in this order: black, purple, blue, green, yellow, orange, red, pink, white",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/falling.webp",
                alt: "colored pencils falling down a white background.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/name.webp",
                alt: "colored pencils falling down a white background.",
              },
            ]}
          /> */}
          <button className="button" onClick={toggleForm}>
            GET ONE
          </button>

          <h2 style={{ marginTop: "4rem" }}>FREQUENTLY-ASKED QUESTIONS</h2>
          <Accordion title="HOW BIG IS IT?">
            <p className="o64">
              right now, they only come in one size: 1" diameter (it's 1" thick)
              and 6" long (including the point).
            </p>
          </Accordion>
          <Accordion title="DOES IT HURT?">
            <p className="o64">
              YES! oak is a{" "}
              <a
                href="https://en.wikipedia.org/wiki/Oak#Timber"
                target="_blank"
                rel="noopener noreferrer"
                className="inlink"
              >
                hard wood
              </a>
              , and we sharpen every colored paincil to a fine point. don't let
              the paint fool you—this is, essentially, a wooden stake—the same
              thing they used to bring your favorite vampire's story to a bitter
              end.
            </p>
            <p className="o64">
              on a serious note, it does make punches stronger by providing
              additional support to your fists, and striking someone with the
              point—even if it's not very hard—will do some serious damage.
            </p>
          </Accordion>
          <Accordion title="CAN I WRITE WITH IT?">
            <p className="o64">
              no, it doesn't color like a real colored pencil—but that's a good
              thing, as it means it won't get dull over time.
            </p>
          </Accordion>
          <Accordion title="CAN I REALLY TAKE IT ANYWHERE?">
            <p className="o64">
              so far, we've never been stopped with one (and we've never tried to hide them).
            </p>
          </Accordion>
          <button onClick={toggleForm} className="button">
            GET ONE
          </button>
        </div>
      </div>
      <div className="column p01">
        <h2 style={{ marginTop: "4rem" }}>MORE TO SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE ART"
            secondary="handmade wire names and charms!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-art">
                GET WIRE ART
              </Link>
            }
          />
          <Card
            primary="WOOD BURNING"
            secondary="professional pyrogrpahy portraits!"
            button={
              <Link className="link" to="/wood-burning">
                GET A WOOD BURNING
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColoredPaincils;
