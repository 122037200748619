import React, { useState } from "react";
import css from "./Pictures.module.css";
import Overlay from "./Overlay";
import Picture from "./Picture";

const Pictures = ({ noClick, pictures, style }) => {
  const [index, setIndex] = useState(null);

  return (
    <div className={css.pictures} style={style}>
      {index !== null && (
        <Overlay pictures={pictures} index={index} setIndex={setIndex} />
      )}
      {pictures.map((picture, index) => (
        <Picture
          picture={picture}
          index={index}
          key={index}
          onClick={() => {
            if (!noClick) setIndex(index);
          }}
        />
      ))}
    </div>
  );
};

export default Pictures;
