import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import css from "./Header.module.css";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let link = (
    <Link to="/" className={css.home}>
      {"< home"}
    </Link>
  );

  let path = location.pathname.split("/");
  if (path[2] === "cart" || (path[1] === "food" && !!path[2])) {
    link = (
      <button onClick={() => navigate(-1)} className={css.home}>
        {"< back"}
      </button>
    );
  }

  if (["/", "/admin/live/view"].includes(location.pathname)) link = null;

  return <div className={css.header}>{link}</div>;
};

export default Header;
