import React from "react";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
import Card from "../../components/card/Card";
import Accordion from "../../components/accordion/Accordion";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  return (
    <div className="main">
      <Intro
        primary="SOCIAL MEDIA"
        secondary="i've done well for myself on social media. i've gone viral at least once on every major platform; i've helped more than 30 companies effectively leverage social media marketing; and i still use it to sell products and build brands. if you want to see what i've been up to online..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>AS SEEN ON</h2>
        <div className="flex wrap">
          <Card
            primary="BUZZFEED"
            img={{
              src: require("./pictures/buzzfeed.jpg"),
              alt: "buzzfeed logo",
            }}
            button={
              <a
                className="link"
                href="https://www.buzzfeednews.com/article/remysmidt/aristotle-comes-through"
                target="_blank"
                rel="noreferrer noopener"
              >
                READ THE ARTICLE
              </a>
            }
          />
          <Card
            primary="MEN'S HUMOR"
            img={{
              src: require("./pictures/mens_humor.jpg"),
              alt: "men's humor logo",
            }}
            button={
              <a
                className="link"
                href="https://www.instagram.com/p/CN-EPx2HJUG/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
              >
                VIEW THE POST
              </a>
            }
          />
          <Card
            primary="THE TINDER BLOG"
            img={{
              src: require("./pictures/the_tinder_blog.jpg"),
              alt: "the tinder blog logo",
            }}
            button={
              <a
                className="link"
                href="https://www.instagram.com/p/CQ_jas3B0Oy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                VIEW THE POST
              </a>
            }
          />
          <Accordion title="AND MORE">
            <a
              className="link"
              href="https://www.instagram.com/thedad/p/CyM3nY3uM-u/"
              target="_blank"
              rel="noreferrer noopener"
            >
              THE DAD
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/BsMK0zMAVqR/"
              target="_blank"
              rel="noreferrer noopener"
            >
              BUZZFEED (AGAIN)
            </a>
            <a
              className="link"
              href="https://www.yahoo.com/news/professor-helps-student-ask-crush-102620264.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              YAHOO
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/B5JPze4n-db/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @SHIP
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/C8WwgOcOMNT/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @FEAROFGOINGOUT
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/Br9I8g3gLDJ/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @NO.F*CKSGIIVEN
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/CdQ5v5nutzp/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @ASAFEPLACEINSIDEYOURHEAD
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/COn_Xg5jMyF/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @IAMTHIRTYAF
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/Bi5d5jzF3S7/?img_index=1"
              target="_blank"
              rel="noreferrer noopener"
            >
              @SELFHELPTHREADS
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/BcD-8PlHVm7/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @KALESALADQUOTES
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/C7J4Gp2L-U6/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @MOMMYWINETIME
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/CWUjj_MLpsk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @THEBASICGAYDRUNK
            </a>
            <a
              className="link"
              href="https://www.instagram.com/p/COokK7-ry_f/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @GIRLSTHINKIMFUNNY
            </a>
            <a
              className="link"
              href="https://www.instagram.com/thedad/p/CyM3nY3uM-u/"
              target="_blank"
              rel="noreferrer noopener"
            >
              MY BIGGEST HATER
            </a>
            <p className="ctext">...and plenty more.</p>
          </Accordion>
        </div>

        <h2 style={{ marginTop: "4rem" }}>VIRAL CONTENT</h2>
        <Pictures
          pictures={[
            { src: require("./pictures/Frame 1.jpg"), alt: "d" },
            { src: require("./pictures/Frame 2.jpg"), alt: "d" },
            { src: require("./pictures/Frame 3.jpg"), alt: "d" },
            { src: require("./pictures/Frame 4.jpg"), alt: "d" },
            { src: require("./pictures/Frame 5.jpg"), alt: "d" },
            { src: require("./pictures/Frame 6.jpg"), alt: "d" },
            { src: require("./pictures/alexa.jpg"), alt: "d" },
            { src: require("./pictures/bella_from_twilight.jpg"), alt: "d" },
            { src: require("./pictures/dads_signature.jpg"), alt: "d" },
            { src: require("./pictures/dead_grandma.jpg"), alt: "d" },
            { src: require("./pictures/horse_divorce.jpg"), alt: "d" },
            { src: require("./pictures/lingerie.jpg"), alt: "d" },
          ]}
        />

        <h2 style={{ marginTop: "4rem" }}>I CAN EXPLAIN</h2>
        <Accordion title="SO, I WENT MEGA-VIRAL">
          <p className="o64">
            i was working a dead-end job and going to college when i went viral
            on twitter for the first time. and when i say "viral", i mean it,
            but if you don't belive me, you can ask{" "}
            <a
              className="inlink"
              href="https://www.ldsliving.com/lds-math-major-asks-english-teacher-how-to-ask-out-a-girl-his-tweet-goes-crazy-on-twitter/s/86822"
              target="_blank"
              rel="noreferrer noopener"
            >
              lds living
            </a>
            , or{" "}
            <a
              className="inlink"
              href="https://www.iheart.com/content/2017-11-01-student-asks-his-professor-for-help-asking-a-girl-out-succeeds/"
              target="_blank"
              rel="noreferrer noopener"
            >
              iheart
            </a>
            , or{" "}
            <a
              className="inlink"
              href="https://littlethings.com/lifestyle/student-professor-help-with-date"
              target="_blank"
              rel="noreferrer noopener"
            >
              little things
            </a>
            , or{" "}
            <a
              className="inlink"
              href="https://www.boredpanda.com/english-professor-helped-student-ask-girl-first-date-squidslippers/"
              target="_blank"
              rel="noreferrer noopener"
            >
              boredpanda
            </a>
            , or{" "}
            <a
              className="inlink"
              href="https://www.buzzfeednews.com/article/remysmidt/aristotle-comes-through"
              target="_blank"
              rel="noreferrer noopener"
            >
              buzzfeed
            </a>
            , or{" "}
            <a
              className="inlink"
              href="https://www.askmen.com/news/dating/this-tweet-demonstrates-exactly-how-to-ask-a-woman-on-a-date.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              askmen
            </a>
            , or{" "}
            <a
              className="inlink"
              href="https://www.fox13now.com/2017/10/30/slcc-student-asks-teacher-for-help-in-asking-girl-out-on-a-date"
              target="_blank"
              rel="noreferrer noopener"
            >
              fox 13
            </a>
            , or a few other places—and these are just the ones that{" "}
            <i>interviewed</i> me.
          </p>
          <p className="o64">
            it's true: i was interviewed by buzzfeed, the local news, the radio,
            and more. (and there was even this whole scandal that i can tell you
            about later.) it was a huge deal! by the end of it, i had trended in
            over 20 countries and had increased my following from 49 (yes,
            really) to over 30,000!
          </p>
        </Accordion>
        <Accordion title="THEN, I GAINED EXPERIENCE">
          <p className="o64">
            at the time, i was working for a local company. once they saw my
            success, they let me manage their social media. then another company
            let me manage theirs—then another. it wasn't long before i was
            independently managing 6+ social media accounts for different
            companies. i was officially a freelance social media manager! i had
            some successes, but i also got screwed over several times, so i
            decided to switch directions.
          </p>
          <p className="o64">
            i ended up settling down at a digital marketing company. while i
            worked there, i did some independent research and practice and
            developed my skills in photography, videography, graphic design, and
            website development. i have since worked professionally as a
            photographer, videographer, writer, content strategist, and content
            creator, both for myself and for several companies. and i've managed
            to go viral consistently on platforms like twitter, tiktok, and
            linkedin on my personal account and some business accounts.
          </p>
        </Accordion>
        <Accordion title="NOW, I MONETIZE SOCIAL MEDIA">
          <p className="o64">
            these days, i'm starting to get more active—and more serious—on
            tiktok and youtube. for example, my website doubles as a platform
            for live sweepstakes giveaways where users can enter the wire names
            they want, and if i choose them, they can enter their information to
            watch me make their name and buy it for 50% off!
          </p>
          <p className="o64">
            i'm also helping a few clients with their content strategy, as well
            as content creation and user-generated content.
          </p>
          <Link className="link" to={"/content-and-design"}>
            CONTENT + DESIGN
          </Link>
        </Accordion>
        <h2 style={{ marginTop: "4rem" }}>FOLLOW ME</h2>
        <div className="flex wrap">
          <Card
            primary="LINKEDIN"
            button={
              <a
                className="link"
                href="https://www.linkedin.com/in/jacob-moreno/"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="YOUTUBE"
            button={
              <a
                className="link"
                href="https://www.youtube.com/@squidslippers"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="TIKTOK"
            button={
              <a
                className="link"
                href="https://www.tiktok.com/@squidslippers"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
