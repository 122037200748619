import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Card from "../../components/card/Card";
import Accordion from "../../components/accordion/Accordion";
import LI from "../../components/LI/LI";

const WebsiteDevelopment = () => {
  return (
    <div className="main">
      <Intro
        primary="SOFTWARE + WEBSITES"
        secondary="I have 10+ years of experience designing, building, and marketing software and websites that have been viewed by millions worldwide. If you need a sleek, user-friendly website—and a clear plan to drive users and revenue..."
        tertiary="You're in the right place."
      />
      <div className="column p01">
        <h2>FOR EXAMPLE</h2>
        <div className="flex wrap">
          <Card
            primary="SQUIDSLIPPERS"
            img={{
              src: require("./ss.jpg"),
              alt: "the squidslippers logo",
            }}
            button={<p className="flink">YOU'RE ALREADY HERE</p>}
          />
          <Card
            primary="CRYSTAL CLEAR CONSULTING"
            img={{
              src: require("./ccc.jpg"),
              alt: "the crystal clear consulting logo: a purple gemstone tilted onto its left side.",
            }}
            button={
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://crystal-clear-consulting.com/"
                className="link"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="SKALLYWAG MEDIA"
            img={{
              src: require("./sm.jpg"),
              alt: "the skallywag media logo: a cartoonish skill (a circle with two vertical oval eyes) over two crossbones.",
            }}
            button={
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://skallywagmedia.com/"
                className="link"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="VIRTUAL COOKBOOK"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/food/brussels_sprouts_bacon.webp",
              alt: "brussels sprouts and bacon in a pan",
            }}
            button={
              <Link className="link" to={"/food"}>
                MAKE SOME FOOD
              </Link>
            }
          />
          <Card
            primary="CLOUD MONEY"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/cm2x1.jpg",
              alt: "cloud money logo",
            }}
            button={
              <a
                className="link"
                href="https://cloudmoney.wm.r.appspot.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="REACHCX"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/cd2x1.png",
              alt: "customer dynamics logo",
            }}
            button={
              <a
                className="link"
                href="https://www.customerdynamics.com/reachcx"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="SAFE SELECT"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/cd2x1.png",
              alt: "customer dynamics logo",
            }}
            button={
              <a
                className="link"
                href="https://www.customerdynamics.com/safe-select"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
          <Card
            primary="MANTLE EXPERIENCE MANAGEMENT"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/mxm2x1.jpg",
              alt: "mantle experience management logo",
            }}
            button={<p className="flink">COMING SOON</p>}
          />
          <Card
            primary="TJ HOLDMAN"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/tjh2x1.png",
              alt: "tj holdman logo",
            }}
            button={<p className="flink">COMING SOON</p>}
          />
          {/* <Card
            primary="ODYSSEY"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/cu2x1.png",
              alt: "strategic education logo",
            }}
            button={<p className="flink">COMING SOON</p>}
          />
          <Card
            primary="ELLA + IRVING"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/su2x1.png",
              alt: "strategic education logo",
            }}
            button={<p className="flink">COMING SOON</p>}
          /> */}
          <Card
            primary="ENTERPRISE AI"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/sei2x1.png",
              alt: "strategic education logo",
            }}
            button={<p className="flink">INTERNAL TOOL</p>}
          />
          <Card
            primary="DIALOG MANAGEMENT CONSOLE"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/sei2x1.png",
              alt: "strategic education logo",
            }}
            button={<p className="flink">INTERNAL TOOL</p>}
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>BUILD YOUR PROJECT</h2>
        <Accordion title="CONTACT + COORDINATE">
          <LI>
            <p>
              reach out to me (in whichever way you prefer) and talk about your
              vision. no tech talk—just your goals and what you're hoping to
              achieve.
            </p>
          </LI>
          <LI>
            <p>
              don't worry if you need help defining what you want—i can help
              with that too! i have plenty of experience with branding,
              strategy, and brainstorming.
            </p>
          </LI>
          <LI>
            <p>
              once we all see your vision clearly, we break the project down
              into milestones, timelines, and costs, giving you a clear
              understanding of exactly what it will take to bring your vision to
              life.
            </p>
          </LI>
        </Accordion>
        <Accordion title="DESIGN + PROTOTYPE">
          <LI>
            <p>
              i create a visual preview of your [ software / website ] so you
              can experience how it will look and feel. then you tell me what
              you think so we can work together to improve it.
            </p>
          </LI>
          <LI>
            <p>
              if you need additional content for your website, such as pictures,
              videos, graphics, icons, or written content, i can help with that!
              my background is in digital marketing and content creation.
            </p>
          </LI>
          <Link className="link" to={"/content-and-design"}>
            CONTENT + DESIGN
          </Link>
        </Accordion>
        <Accordion title="DEVELOP + TEST">
          <LI>
            <p>
              after the look and feel have been solidified, i bring the vision
              to life. i use whichever platforms you prefer to develop the
              project and make sure every user has a great experience.
            </p>
          </LI>
          <LI>
            <p>
              i'll also help you cover your bases like cybersecurity and legal
              compliance—and i'll teach you about the development process along
              the way.
            </p>
          </LI>
        </Accordion>
        <Accordion title="LAUNCH + SUPPORT">
          <LI>
            <p>
              when we're ready to go, we'll launch the product and start
              spreading the word to your users.
            </p>
          </LI>
          <LI>
            <p>
              on the back-end, i'll make sure your project is always available
              to users worldwide, maintaining all necessary servers, domains,
              and other assets.
            </p>
          </LI>
          <LI>
            <p>
              on the front-end, i can help you reach the right audience with the
              right content at the right time.
            </p>
          </LI>
          <Link className="link" to={"/advertising-and-marketing"}>
            ADVERTISING + MARKETING
          </Link>
        </Accordion>
        <div className="flex wrap">
          <Card
            primary="CALL ME"
            button={
              <a href="tel:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="TEXT ME"
            button={
              <a href="sms:+18018678774" className="link">
                801-867-8774
              </a>
            }
          />
          <Card
            primary="EMAIL ME"
            button={
              <a href="mailto:jacob.w.moreno@gmail.com" className="link">
                jacob.w.moreno@gmail.com
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WebsiteDevelopment;
