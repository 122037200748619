import React, { useContext, useEffect } from "react";
import NotificationsContext from "./context/notifications";
import "./App.css";
import Header from "./components/header/Header";
import Cart from "./components/cart/Cart";
import CartPage from "./pages/cart/Cart";
import Footer from "./components/footer/Footer";
import IAMContext from "./context/iam";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Manage from "./pages/admin/live/Manage";
import Login from "./pages/admin/login/Login";
import Dashboard from "./pages/home/Dashboard";
import Art from "./pages/art/Art";
import Photography from "./pages/photography/Photography";
import PrivacyPolicy from "./pages/privacy_policy/PrivacyPolicy";
import Checkout from "./pages/checkout/Checkout";
import Order from "./pages/orders/order/Order";
import WireArt from "./pages/wire_art/WireArt";
import SocialMedia from "./pages/social_media/SocialMedia";
import SoftwareEngineering from "./pages/software_engineering/SoftwareEngineering";
import WebsiteDevelopment from "./pages/software_and_websites/SoftwareAndWebsites";
import Winner from "./pages/sweepstakes/Winner";
import View from "./pages/admin/live/View";
import Shop from "./pages/shop/Shop";
import ColoredPaincils from "./pages/colored_paincils/ColoredPaincils";
import VideoGames from "./pages/video_games/VideoGames";
import AnimalCrossing from "./pages/video_games/AnimalCrossing";
import Food from "./pages/virtual_cookbook/VirtualCookbook";
import BrusselsSproutsAndBacon from "./pages/virtual_cookbook/recipes/BrusselsSproutsAndBacon";
import Pancakes from "./pages/virtual_cookbook/recipes/Pancakes";
import EggDropSoup from "./pages/virtual_cookbook/recipes/EggDropSoup";
import IncineratedToast from "./pages/virtual_cookbook/recipes/IncineratedToast";
import WoodBurning from "./pages/wood_burning/WoodBurning";
import ContentAndDesign from "./pages/content_and_design/ContentAndDesign";

function App() {
  const { array } = useContext(NotificationsContext);
  const { token } = useContext(IAMContext);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  let adminLogin, adminDashboard, manageLive, viewLive;

  if (token) {
    adminDashboard = <Dashboard />;
    adminLogin = <Navigate replace to="/admin/dashboard" />;
    manageLive = <Manage />;
    viewLive = <View />;
  } else {
    adminDashboard = <Navigate replace to="/admin/login" />;
    adminLogin = <Login />;
    manageLive = <Navigate replace to="/admin/login" />;
    viewLive = <Navigate replace to="/admin/login" />;
  }

  return (
    <div className="App">
      {array}
      <Header />
      <Cart />
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/admin/dashboard" element={adminDashboard} />
        <Route path="/admin/live/manage" element={manageLive} />
        <Route path="/admin/live/view" element={viewLive} />
        <Route path="/admin/login" element={adminLogin} />

        <Route path="/art" element={<Art />} />
        <Route path="/content-and-design" element={<ContentAndDesign />} />
        <Route path="/food" element={<Food />} />
        <Route
          path="/food/brussels-sprouts-and-bacon"
          element={<BrusselsSproutsAndBacon />}
        />
        <Route path="/food/egg-drop-soup" element={<EggDropSoup />} />
        <Route path="/food/incinerated-toast" element={<IncineratedToast />} />
        <Route path="/food/pancakes" element={<Pancakes />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/cart" element={<CartPage />} />
        <Route path="/shop/checkout" element={<Checkout />} />
        <Route path="/shop/order/:id" element={<Order />} />
        <Route path="/colored-paincils" element={<ColoredPaincils />} />
        <Route path="/wire-art" element={<WireArt />} />
        <Route path="/wood-burning" element={<WoodBurning />} />

        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/software-engineering" element={<SoftwareEngineering />} />

        <Route path="/sweepstakes/winner/:id" element={<Winner />} />
        <Route path="/video-games" element={<VideoGames />} />
        <Route
          path="/video-games/animal-crossing"
          element={<AnimalCrossing />}
        />
        <Route path="/software-and-websites" element={<WebsiteDevelopment />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
