import React from "react";
import { Link } from "react-router-dom";
// COMPONENTS
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
import Accordion from "../../components/accordion/Accordion";
// ASSETS
import clouds1 from "../photography/pictures/clouds1.jpg";
import parents from "../photography/pictures/parents.jpg";
import Card from "../../components/card/Card";

const Home = () => {
  return (
    <div className="main">
      <Intro
        primary="WELCOME!!"
        secondary="if you want to learn about art, comedy, eevee, maple, music, photography, psychology, social media, software engineering, storytelling, video games, website development, writing, and other things i love..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>SHOP</h2>
        <div className="flex wrap">
          <Card
            primary="WIRE ART"
            secondary="handmade wire names and charms!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/art/wire/falling.png",
              alt: "wire names scattered across a black background",
            }}
            button={
              <Link className="link" to="/wire-art">
                GET SOME
              </Link>
            }
          />

          <Card
            primary="COLORED PAINCILS"
            secondary="fun for (almost) everybody!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/colored_paincils/preview.png",
              alt: "a row of colored pencils (from left to right: white, pink, red, orange, yellow, green, blue, purple, black) against a black background",
            }}
            button={
              <Link className="link" to="/colored-paincils">
                GET ONE
              </Link>
            }
          />

          <Card
            primary="WOOD BURNING"
            secondary="professional pyrogrpahy portraits!"
            button={
              <Link className="link" to="/wood-burning">
                GET ONE
              </Link>
            }
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/wood_burning/britain.webp",
              alt: "a husband and wife looking at each other",
            }}
          />
        </div>

        <h2 style={{ marginTop: "4rem" }}>COLLABORATE</h2>

        <Accordion title="SOFTWARE + WEBSITES">
          <Link className="link" to="/software-and-websites">
            LEARN MORE
          </Link>
        </Accordion>
        <Accordion title="CONTENT + DESIGN">
          <Link className="link" to="/content-and-design">
            LEARN MORE
          </Link>
        </Accordion>

        <h2 style={{ marginTop: "4rem" }}>RESOURCES</h2>
        <div className="flex wrap">
          <Card
            primary="VIRTUAL COOKBOOK"
            secondary="with an interactive step-by-step guide for several recipes!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/food/brussels_sprouts_bacon.webp",
              alt: "brussels sprouts and bacon in a pan",
            }}
            button={
              <Link className="link" to={"/food"}>
                MAKE SOME FOOD
              </Link>
            }
          />
          <Card
            primary="CLOUD MONEY"
            secondary="finally, a budgeting tool for inconsistent incomes!"
            img={{
              src: "https://storage.googleapis.com/squidslippers-media/logo/cm2x1.jpg",
              alt: "cloud money logo",
            }}
            button={
              <a
                className="link"
                href="https://cloudmoney.wm.r.appspot.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                CHECK IT OUT
              </a>
            }
          />
          {/* <Card primary="PWNY EXPRESS" secondary="soon!" placeholder img={{}} />
          <Card
            primary="GAME SCORE KEEPER"
            secondary="soon!"
            placeholder
            img={{}}
          />
          <Card primary="PACKING LIST" secondary="soon!" placeholder img={{}} /> */}
        </div>

        <h2 style={{ marginTop: "4rem" }}>ME</h2>

        <Accordion title="ART">
          <p className="o64">
            i make, perform, sell, and give away all kinds of art: wood burning,
            logo design, digital art, painting, sculpting, drawing, and more—you
            name it, i've (probably) tried it!
          </p>
          <p>
            here's where you can browse my art portfolio and buy some of my
            custom-made art.
          </p>
          <Pictures
            key={1}
            pictures={[
              {
                src: "https://storage.googleapis.com/squidslippers-media/wood_burning/potter.webp",
                alt: "a husband and wife looking at each other",
                description:
                  "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/egg.webp",
                alt: "egg",
                description:
                  "a hyper-realistic egg i drew using procreate.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/squidslippers.webp",
                alt: "squidslippers",
                description: "my squidslippers logo! i love this little guy!",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/art/pig.webp",
                alt: "pig",
                description: "an acrylic painting i made as a commission.",
              },
            ]}
          />
          <Link className="button" to={"/art"}>
            EXPLORE MY ART
          </Link>
        </Accordion>

        <Accordion title="PHOTOGRAPHY">
          <p className="o64">
            i'm not a professional or anything, but i do have a nice camera, a
            basic understanding of photography, a lot of free time, and a few
            neat pictures to show for it.
          </p>
          <p>here's where you can browse the pictures i've taken!</p>
          <Pictures
            key={2}
            pictures={[
              {
                src: "https://storage.googleapis.com/squidslippers-media/nature/raja.webp",
                alt: "red, white, and blue betta fish",
                description:
                  "anika and i love betta fish. everyone, this is raja. raja, everyone.",
              },
              {
                src: "https://storage.googleapis.com/squidslippers-media/lifestyle/feet.webp",
                alt: "feet prints in sand",
                description:
                  "getting a picture like this is NOT easy, i'll tell you that much.",
              },
              {
                src: clouds1,
                alt: "clouds",
                description:
                  "(the sky is the artist here; i just took the picture.)",
              },
              {
                src: parents,
                alt: "parents",
                description:
                  "my mom and dad are so cute and great! just look at 'em!",
              },
            ]}
          />
          <Link className="button" to={"/photography"}>
            EXPLORE MY PHOTOGRAPHY
          </Link>
        </Accordion>

        <Accordion title="SOCIAL MEDIA">
          <p className="o64">
            i've done quite well for myself on social media. my content has gone
            viral on just about every major platform, with over 300,000,000
            combined views. i've been featured on major platforms like buzzfeed,
            f***jerry...
          </p>
          <a
            href="https://www.youtube.com/@squidslippers"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            TIKTOK
          </a>
          <a
            href="https://www.youtube.com/@squidslippers"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            YOUTUBE
          </a>
          <a
            href="https://www.linkedin.com/in/jacob-moreno/"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            LINKEDIN
          </a>
          <a
            href="https://www.instagram.com/squidslippers/"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            INSTAGRAM
          </a>
        </Accordion>
      </div>
    </div>
  );
};

export default Home;
