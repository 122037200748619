import React, { forwardRef } from "react";
import css from "./Radio.module.css";

const Radio = forwardRef(
  ({ error, onChange, options, selected, title }, ref) => {
    const legendClass = `${
      selected === false || !!selected ? "" : css.legendBlank
    } ${error ? css.legendError : ""}`;

    return (
      <fieldset className={css.main} ref={ref}>
        {error ||
          (title && (
            <strong className={legendClass}>
              {error ? error?.toUpperCase() : title?.toUpperCase()}
            </strong>
          ))}
        {options?.map((option, index) => {
          return (
            <button
              type="button"
              key={(option, index)}
              className={`${css.option} ${
                option.value === selected ? css.selected : undefined
              }`}
              onClick={() => onChange(option.value)}
            >
              <div
                className={`${css.box} ${
                  selected === option.value ? css.checked : undefined
                }`}
              >
                {selected === option.value && (
                  <img
                    className={css.check}
                    src={require("./check.png")}
                    alt="checkmark"
                  />
                )}
              </div>
              <input
                onChange={onChange}
                type="radio"
                value={option.value}
                checked={selected === option.value}
              />
              <label className={css.label} htmlFor={option}>
                {option.display}
              </label>
              {option.right && <div className={css.info}>${option.right}</div>}
            </button>
          );
        })}
      </fieldset>
    );
  }
);

export default Radio;
